import { gql } from 'apollo-boost'

export const GET_ORDERS = gql`
  {
    orders {
      id
      OrderNo
      CustomerCode
      CustomerName
      Address1
      Address2
      ZoneID
      NoOfCarton
      Remarks
      Status
      CreatedBy
      CreatedOn
      ModifiedBy
      LastModified
      DeliveryCharges
      PaymentMethod
    }
  }
`

export const GET_ORDERDETAILS = gql`
  query OrderDetails($OrderID: ID!) {
    orderdetails(OrderID: $OrderID) {
      id
      OrderID
      OrderNo
      UserID
      ProductID
      InvoiceAmount
      Qty
      CreatedDate
      PriceID
      UnitPrice
      ProductName
      Category
      ProductImage
    }
  }
`

export const GET_ORDER_STATUS = gql`
  query OrderStatus($OrderID: ID!) {
    orderstatus(OrderID: $OrderID) {
      id
      OrderID
      Status
      CreatedBy
      CreatedOn
    }
  }
`

export const GET_MYORDERS = gql`
  {
    myorders {
      id
      OrderNo
      Remarks
      Status
      CreatedBy
      CreatedOn
      ModifiedBy
      LastModified
      StatusCode
      TotalItem
      TotalAmount
      DeliveryCharges
      PaymentMethod
    }
  }
`

export const GET_MYORDER = gql`
  query MyOrder($OrderNo: String!) {
    myorder(OrderNo: $OrderNo) {
      id
      OrderNo
      Remarks
      Status
      CreatedBy
      CreatedOn
      ModifiedBy
      LastModified
      StatusCode
      TotalItem
      TotalAmount
      PaymentMethod
      DeliveryCharges
      OrderDetails {
        id
        OrderID
        OrderNo
        UserID
        ProductID
        InvoiceAmount
        Qty
        CreatedDate
        PriceID
        UnitPrice
        ProductName
        Category
        ProductImage
      }
    }
  }
`

export const CART_ORDER = gql`
  mutation CartOrder($PaymentMethod: String) {
    cartorder(PaymentMethod: $PaymentMethod)
  }
`

export const GET_NOZONE_ORDERS = gql`
  {
    nozoneorders {
      id
      OrderNo
      CustomerCode
      CustomerName
      Address1
      Address2
      ZoneID
      NoOfCarton
      Remarks
      Status
      CreatedOn
    }
  }
`

export const UPDATE_ZONE = gql`
  mutation UpdateZone(
    $id: ID!
    $ZoneID: String
    $Status: String
    $Remarks: String
    $CreatedBy: String
    $StatusText: String
  ) {
    updatezone(
      id: $id
      ZoneID: $ZoneID
      Status: $Status
      Remarks: $Remarks
      CreatedBy: $CreatedBy
      StatusText: $StatusText
    ) {
      id
      OrderNo
      CustomerCode
      CustomerName
      Address1
      Address2
      ZoneID
      NoOfCarton
      Remarks
      Status
      CreatedOn
    }
  }
`

export const CREATE_ORDER = gql`
  mutation CreateOrder(
    $CustomerCode: String!
    $NoOfCarton: String
    $Remarks: String
    $User: String
  ) {
    createorder(
      CustomerCode: $CustomerCode
      NoOfCarton: $NoOfCarton
      Remarks: $Remarks
      User: $User
    ) {
      id
      OrderNo
      CustomerCode
      CustomerName
      Address1
      Address2
      ZoneID
      NoOfCarton
      Remarks
      Status
      CreatedBy
      CreatedOn
      ModifiedBy
      LastModified
    }
  }
`

export const UPDATE_ORDER = gql`
  mutation UpdateOrder(
    $id: ID!
    $CustomerCode: String!
    $NoOfCarton: String
    $Remarks: String
    $User: String
  ) {
    updateorder(
      id: $id
      CustomerCode: $CustomerCode
      NoOfCarton: $NoOfCarton
      Remarks: $Remarks
      User: $User
    ) {
      id
      OrderNo
      CustomerCode
      CustomerName
      Address1
      Address2
      ZoneID
      NoOfCarton
      Remarks
      Status
      CreatedBy
      CreatedOn
      ModifiedBy
      LastModified
    }
  }
`

export const REMOVE_ORDER = gql`
  mutation RemoveOrder($id: ID!) {
    removeorder(id: $id)
  }
`
