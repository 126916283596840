import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import PostcodeDialog from './postcodeDialog'
import { GET_POSTCODES } from '../graphql/postcode'
import ConfirmationDialog from '../common/confirmationDialog'
import Loading from '../common/loading'
import RefreshIcon from '@material-ui/icons/Refresh'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'Town',
    label: 'Town',
  },
  {
    id: 'PostCode',
    label: 'Postcode',
  },
  {
    id: 'Price',
    label: 'Price',
    currency: true,
  } /* 
  {
    id: 'Quantity',
    label: 'Quantity',
  },
  {
    id: 'UnitPrice',
    label: 'UnitPrice',
  },
  {
    id: 'UnitsInStock',
    label: 'UnitsInStock',
  },
  {
    id: 'UnitsInOrder',
    label: 'UnitsInOrder',
  }, */,
  ,
]

export default function PostCode() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [postcode, setPostcode] = useState()

  const {
    loading: loadingPostcodes,
    data: { postcodes } = { postcodes: [] },
    refetch: refetchPostcodes,
    error,
  } = useQuery(GET_POSTCODES)

  const handleClickOpen = () => {
    setPostcode()
    setSelected([])
    setOpen(true)
  }

  if (loadingPostcodes) return <Loading />
  if (error) return <p>API ERROR</p>

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        disableElevation
        classes={{ root: classes.newButton }}
        color="primary"
        onClick={handleClickOpen}
      >
        New
      </Button>
      <Button
        variant="outlined"
        classes={{ root: classes.newButton }}
        style={{ marginLeft: 5 }}
        color="primary"
        startIcon={<RefreshIcon />}
        onClick={() => {
          refetchPostcodes()
        }}
      >
        Refresh
      </Button>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <PostcodeDialog
            setOpen={setOpen}
            setSelected={setSelected}
            setPostcode={setPostcode}
            data={postcode}
            open={open}
            refetchPostcodes={refetchPostcodes}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableHalf
            clickOnSelect={true}
            hidePagination={true}
            hideChange={true}
            hideDelete={true}
            selected={selected}
            setSelected={setSelected}
            tableState={setPostcode}
            tableData={postcodes}
            setOpen={setOpen}
            tableHead={tableHead}
          ></TableHalf>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          {order && order.id && (
            <div className={classes.displayDiv}>
              <ProductDisplay data={order} />
            </div>
          )}
        </Grid> */}
      </Grid>
      {/*  <pre>{JSON.stringify(orders, null, 4)}</pre> */}
    </div>
  )
}
