import React, { useState, useEffect } from 'react'
import {
  Router,
  Redirect,
  Route,
  BrowserRouter,
  Switch,
  withRouter,
  Link,
  Link as Link2,
} from 'react-router-dom'

import clsx from 'clsx'
import Cookies from 'js-cookie'
import Login from './Login'
import PrivateArea from './PrivateArea'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBox,
  faUserTie,
  faTruck,
  faPlane,
  faFileInvoiceDollar,
  faCog,
  faCalculator,
  faPowerOff,
  faFileAlt,
  faFolderOpen,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons'

import axios from 'axios'
import './App.css'

import {
  fade,
  makeStyles,
  useTheme,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/HomeOutlined'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

import IconButton from '@material-ui/core/IconButton'
import FacebookIcon from '@material-ui/icons/Facebook'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import ScheduleIcon from '@material-ui/icons/Schedule'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import SettingsIcon from '@material-ui/icons/Settings'
import AccountCircle from '@material-ui/icons/AccountCircle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import StorefrontIcon from '@material-ui/icons/Storefront'

import Collapse from '@material-ui/core/Collapse'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import StarBorder from '@material-ui/icons/StarBorder'

import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { GET_SHOPCONFIG } from './components/graphql/config'
import { GET_BUYER } from './components/graphql/buyer'
import { SnackbarProvider } from 'notistack'
import Logout from './Logout'
import Admin from './components/admin/admin'
import Home from './components/pages/home'
import MyProducts from './components/admin/productmain'
import ShopDecoration from './components/admin/shopDecoration'
import Config from './components/admin/config'
import BasicSettings from './components/admin/basicsettings'

const GET_DARKMODE = gql`
  {
    isDarkModeEnabled @client
  }
`
const drawerWidth = 240

const restApi = 'https://frapp.cloud:3330'

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    flexGrow: 1,
    padding: 0,
    textAlign: 'center',
    lineHeight: 1,
    color: (props) => props.COLORPRIMARY,
    textDecoration: 'none',
  },
  toolBar2: {
    margin: 'auto',
    maxWidth: 1199,
    width: '100%',
    minHeight: 48,
    paddingRight: 0,
    paddingLeft: 0,
    backgroundColor: 'white',
    color: (props) => props.COLORPRIMARY,
    [theme.breakpoints.up('md')]: {
      minHeight: 30,
      backgroundColor: 'transparent',
      color: 'white',
    },
  },
  appBar: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  link: { color: (props) => props.COLORPRIMARY, textDecoration: 'none' },
  inline: {
    display: 'inline',
    color: (props) => props.COLORPRIMARY,
  },
  drawer: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
    },
  },
  drawerOpen: {
    background: '#cfd8dc!important',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    background: '#cfd8dc!important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    padding: theme.spacing(6, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  appbarroot: {
    position: 'fixed',
    [theme.breakpoints.up('sm')]: {
      position: 'static',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(7) + 1,
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  icon: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  iconList: {
    color: '#757575',
    minWidth: '26px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
  },
  menuItem: {
    paddingLeft: 10,
    paddingRight: 10,
    textDecoration: 'none',
    fontWeight: 'bold',
    color: 'white',
  },
  signinMenu: {
    paddingRight: 10,
    textDecoration: 'none',
    fontWeight: 'bold',
    color: (props) => props.COLORPRIMARY,
    [theme.breakpoints.up('sm')]: {
      color: 'white',
    },
  },
  menuDivider: {
    height: 30,
    borderLeft: '1px solid rgba(255, 255, 255, 0)',
  },
  listitemroot: {
    minWidth: 250,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

export default function App(props) {
  const [signedIn, setSignedIn] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [redirectTo, setRedirectTo] = useState()

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: [] },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const {
    loading: loadingBuyer,
    data: { buyer } = { buyer: { id: null } },
  } = useQuery(GET_BUYER, { skip: !Cookies.get('signedin') })

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const [open, setOpen] = useState(false)

  const [theme, setTheme] = useState({
    overrides: {
      MuiBox: {
        root: {
          padding: 0,
        },
      },
      MuiPaper: {
        root: {
          borderRadius: 0,
          border: '0!important',
          boxShadow: '0 1px 1px 0 rgba(0,0,0,.05)',
        },
      },
      MuiCard: {
        root: {
          borderRadius: 6,
          border: '0!important',
          boxShadow: '0 1px 1px 0 rgba(0,0,0,.05)',
        },
      },
      MuiButton: {
        root: {
          borderRadius: 6,
        },
      },
    },
    palette: {
      primary: {
        dark: '#fff',
        main: '#fff',
        paper: '#efefef',
        contrastText: '#fff',
      },
      secondary: {
        main: '#fff',
      },
      background: {
        default: '#efefef',
      },
      type: 'light', // Switching the dark mode on is a single property value change.
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    transitions: {
      duration: {
        shortest: 20,
        shorter: 60,
        short: 100,
        standard: 100,
        complex: 175,
        enteringScreen: 125,
        leavingScreen: 95,
      },
    },
  })

  const classes = useStyles(shopconfig)

  /*const [dbhost, setDbhost] = useState(
    window.location.host.endsWith('.frapp.cloud')
      ? window.location.host.split('.frapp.cloud')[0]
      : ''
  )*/

  const [dbhost, setDbhost] = useState('spa1')

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open2 = Boolean(anchorEl)

  const [menuOpen, setMenuOpen] = useState({
    product: false,
    shopdecoration: false,
    basicsettings: false,
  })

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    console.log(dbhost)
  }, [])

  const theme2 = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [dense, setDense] = React.useState(false)

  const {
    data: { isDarkModeEnabled, loginDetails } = {
      isDarkModeEnabled: false,
    },
    client,
  } = useQuery(GET_DARKMODE)

  const handleToggle = () => {
    toggleDarkTheme(!isDarkModeEnabled)
    setDense(!isDarkModeEnabled)
    client.writeData({ data: { isDarkModeEnabled: !isDarkModeEnabled } })
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
    setMenuOpen({ product: true, shopdecoration: true, basicsettings: true })
  }

  const handleDrawerClose = () => {
    setOpen(false)
    setMenuOpen({ product: false, shopdecoration: false, basicsettings: false })
  }

  const sideList = (side) => (
    <div>
      <div>
        <IconButton onClick={handleDrawerClose}>
          {theme2.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider className={classes.divider} />
      <List component="nav" dense>
        <ListItem
          button
          onClick={() => {
            setOpen(true)
            setMenuOpen({ product: !menuOpen.product })
          }}
        >
          <ListItemIcon>
            <ShoppingBasketIcon />
          </ListItemIcon>
          <ListItemText primary="Product" />
          {menuOpen.product ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={menuOpen.product} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            <Link
              className={classes.link}
              to="/myproducts"
              onClick={handleDrawerClose}
            >
              <ListItem button className={classes.nested}>
                <ListItemText primary="My Products" />
              </ListItem>
            </Link>
          </List>
        </Collapse>
      </List>

      <List component="nav" dense>
        <ListItem
          button
          onClick={() => {
            setOpen(true)
            setMenuOpen({ shopdecoration: !menuOpen.shopdecoration })
          }}
        >
          <ListItemIcon>
            <StorefrontIcon />
          </ListItemIcon>
          <ListItemText primary="Shop" />
          {menuOpen.shopdecoration ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={menuOpen.shopdecoration} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            <Link
              className={classes.link}
              to="/shopdecoration"
              onClick={handleDrawerClose}
            >
              <ListItem button className={classes.nested}>
                <ListItemText primary="Shop Decoration" />
              </ListItem>
            </Link>
          </List>
        </Collapse>
      </List>

      <List component="nav" dense>
        <ListItem
          button
          onClick={() => {
            setOpen(true)
            setMenuOpen({ basicsettings: !menuOpen.basicsettings })
          }}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Setting" />
          {menuOpen.basicsettings ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={menuOpen.basicsettings} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            <Link
              className={classes.link}
              to="/basicsettings"
              onClick={handleDrawerClose}
            >
              <ListItem button className={classes.nested}>
                <ListItemText primary="Basic Settings" />
              </ListItem>
            </Link>
          </List>
        </Collapse>
      </List>

      <Divider className={classes.divider} />
    </div>
  )

  // we change the palette type of the theme in state
  const toggleDarkTheme = (isDarkTheme) => {
    if (isDarkTheme) {
      setTheme({
        overrides: {
          MuiBox: {
            root: {
              padding: 0,
            },
          },
          MuiPaper: {
            root: {
              borderRadius: 0,
              border: '0!important',
              boxShadow: '0 1px 1px 0 rgba(0,0,0,.05)',
            },
          },
          MuiCard: {
            root: {
              borderRadius: 6,

              border: '0!important',
              boxShadow: '0 1px 1px 0 rgba(0,0,0,.05)',
            },
          },
          MuiButton: {
            root: {
              borderRadius: 6,
            },
          },
        },
        palette: {
          primary: {
            main: shopconfig.COLORPRIMARY,
            paper: '#323232',
            contrastText: '#000',
          },
          secondary: {
            main: shopconfig.COLORPRIMARY,
          },
          background: {
            default: '#212121',
          },
          type: 'dark', // Switching the dark mode on is a single property value change.
        },
        typography: {
          fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
          fontSize: 14,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
      })
    } else {
      setTheme({
        overrides: {
          MuiBox: {
            root: {
              padding: 0,
            },
          },
          MuiPaper: {
            root: {
              borderRadius: 0,
              border: '0!important',
              boxShadow: '0 1px 1px 0 rgba(0,0,0,.05)',
            },
          },
          MuiCard: {
            root: {
              borderRadius: 6,
              border: '0!important',
              boxShadow: '0 1px 1px 0 rgba(0,0,0,.05)',
            },
          },
          MuiButton: {
            root: {
              borderRadius: 6,
            },
          },
        },
        palette: {
          primary: {
            dark: shopconfig.COLORPRIMARY,
            main: shopconfig.COLORPRIMARY,
            paper: '#f5f5f5',
            contrastText: '#f3f3f3',
          },
          secondary: {
            main: shopconfig.COLORPRIMARY,
          },
          background: {
            default: '#efefef',
          },
          type: 'light', // Switching the dark mode on is a single property value change.
        },
        typography: {
          fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
          fontSize: 14,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
      })
    }
  }

  const muiTheme = createMuiTheme(theme)

  const handleSignedIn = (link) => {
    setSignedIn(true)
    setRedirect(true)
    setRedirectTo(link)
  }

  const renderRedirect = () => {
    if (redirect) {
      setRedirect(false)
      return <Redirect to={redirectTo} />
    }
    if (redirectTo) return <Redirect to={redirectTo} />
  }

  useEffect(() => {
    if (Cookies.get('signedin')) {
      setSignedIn(true)
      axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get(
        'token'
      )}`
      client.writeData({
        data: {
          isDarkModeEnabled,
          loginDetails: {
            StaffID: Cookies.get('StaffID'),
            Department: Cookies.get('Department'),
          },
        },
      })
    }
  }, [])

  useEffect(() => {
    if (shopconfig.COLORPRIMARY)
      setTheme({
        props: {
          // Name of the component ⚛️
          MuiButton: {
            // The default props to change
            size: 'medium', // No more ripple, on the whole application 💣!
          },
        },
        overrides: {
          MuiBox: {
            root: {
              padding: 0,
            },
          },
          MuiOutlinedInput: {
            root: {
              borderRadius: 6,
            },
          },
          MuiPaper: {
            root: {
              borderRadius: '0px!important',
              border: '0!important',
              boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)!important',
            },
          },
          MuiCard: {
            root: {
              borderRadius: 6,
              border: '0!important',
              boxShadow: '0 1px 1px 0 rgba(0,0,0,.05)',
            },
          },
          MuiButton: {
            root: {
              borderRadius: 6,
            },
          },
        },
        palette: {
          primary: {
            dark: shopconfig.COLORPRIMARY,
            main: shopconfig.COLORPRIMARY,
            paper: '#f5f5f5',
            contrastText: '#f3f3f3',
          },
          secondary: {
            main: shopconfig.COLORSECONDARY,
          },
          background: {
            default: '#efefef',
          },
          type: 'light', // Switching the dark mode on is a single property value change.
        },
        typography: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontSize: 15,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
      })
    //if (shopconfig.COMSHO) document.title = `Welcome to ${shopconfig.COMSHO}`
  }, [shopconfig])

  const menus = [
    {
      title: 'Product',
      link: '/product',
      icon: <FontAwesomeIcon icon={faUserTie} classes={classes.icon} />,
      access: ['Management'],
    },
  ]

  return (
    <ThemeProvider theme={muiTheme}>
      <SnackbarProvider>
        <BrowserRouter>
          {renderRedirect()}
          <div className={classes.root}>
            <CssBaseline />

            {signedIn && (
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              >
                <Toolbar variant="dense" style={{ paddingLeft: 20 }}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, {
                      [classes.hide]: open,
                    })}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    noWrap
                  ></Typography>
                </Toolbar>
              </AppBar>
            )}

            {signedIn && (
              <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                  <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                      [classes.drawerOpen]: open,
                      [classes.drawerClose]: !open,
                    })}
                    classes={{
                      paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                      }),
                    }}
                  >
                    {sideList('left')}
                  </Drawer>
                  <Divider className={classes.divider} />
                </Hidden>
                <Hidden xsDown implementation="css">
                  <Divider />
                  <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                      [classes.drawerOpen]: open,
                      [classes.drawerClose]: !open,
                    })}
                    classes={{
                      paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                      }),
                    }}
                  >
                    {sideList('left')}
                  </Drawer>
                  <Divider className={classes.divider} />
                </Hidden>
              </nav>
            )}

            <main className={signedIn ? classes.content : null}>
              <div className={signedIn ? classes.toolbar : null}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={(a) => {
                      if (dbhost) return <MyProducts />
                      else return <Home />
                    }}
                  />
                  <Route path="/myproducts" render={(a) => <MyProducts />} />

                  <Route
                    path="/shopdecoration"
                    render={(a) => (
                      <ShopDecoration {...a} setTheme={setTheme} />
                    )}
                  />
                  <Route
                    path="/basicsettings"
                    render={(a) => <BasicSettings />}
                  />
                  <Route
                    path="/login"
                    render={(a) => <Login {...a} signedIn={setSignedIn} />}
                  />
                  <Route
                    path="/logout"
                    render={(a) => <Logout signedIn={setSignedIn} />}
                  />
                  {/* <Route
                  path="/admin"
                  render={(a) => <Admin {...a} setTheme={setTheme} />}
                ></Route> */}
                </Switch>
                {/* 
                <Divider /> */}
              </div>
            </main>
          </div>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  )
}
