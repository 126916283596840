import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { Redirect } from 'react-router-dom'

export default function Logout(props) {
  Cookies.remove('Department')
  Cookies.remove('StaffID')
  Cookies.remove('jwt')
  Cookies.remove('signedin')
  Cookies.remove('token')
  console.log(props)
  props.signedIn(false)

  return <Redirect to="/" />
}
