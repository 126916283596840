import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import {
  INSERT_POSTCODE,
  UPDATE_POSTCODE,
  GET_POSTCODES,
  REMOVE_POSTCODE,
} from '../graphql/postcode'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import ProductPriceDialog from './productPriceDialog'
import ProductCancelDialog from './productCancelDialog'
import ProductUploadDialog from './productUploadDialog'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { Editor } from '@tinymce/tinymce-react'

const restApi = 'https://frapp.cloud:3330'

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
}))

export default function PostcodeDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setValue } = useForm()
  const [confirm, setConfirm] = useState(false)

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_POSTCODES,
    })
    const latest = data.insertpostcode
    cache.writeQuery({
      query: GET_POSTCODES,
      data: { postcodes: [latest, ...old.postcodes] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setProduct()
  }

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_POSTCODES,
    })
    const latest = data.updatepostcode
    const foundIndex = old.postcodes.findIndex((item) => item.id === latest.id)
    old.postcodes.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_POSTCODES,
      data: { postcodes: [...old.postcodes] },
    })
    props.setOpen(false)
    props.setSelected([])
    props.setProduct()
  }

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_POSTCODES,
    })
    if (data.removepostcode) {
      const latest = old.postcodes.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_POSTCODES,
        data: { postcodes: [...latest] },
      })
      props.setOpen(false)
      props.setSelected([])
      props.setProduct()
    }
  }

  const [insertPostcode] = useMutation(INSERT_POSTCODE, { update: addCache })
  const [updatePostcode] = useMutation(UPDATE_POSTCODE, {
    update: updateCache,
  })
  const [removePostcode] = useMutation(REMOVE_POSTCODE, {
    update: deleteCache,
  })

  const onSubmit = (values) => {
    if (props.data && props.data.id) {
      updatePostcode({
        variables: {
          id: props.data.id,
          Price: parseFloat(values.Price),
        },
      })
    } else {
      insertPostcode({
        variables: {
          Town: values.Town,
          State: values.State,
          PostCode: values.PostCode,
          Price: parseFloat(values.Price),
        },
      })
    }
  }

  const handleClickDelete = () => {
    removePostcode({
      variables: {
        id: props.data.id,
      },
    })
    setConfirm(false)
    props.setOpen(false)
    props.setSelected([])
    props.setPostcode()
  }

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Postcode?"
        okButton="Yes"
        title="Continue remove"
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
          props.setSelected([])
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="order-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle} id="order-dialog">
            Postcode
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
                props.setSelected([])
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={3}>
                <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
                  <TextField
                    disabled={props.data}
                    name="State"
                    label="State"
                    margin="dense"
                    fullWidth
                    defaultValue={props.data && props.data.State}
                    autoComplete="off"
                    inputRef={register({ required: 'Required' })}
                    error={errors.State}
                    helperText={errors.State && errors.State.message}
                  />
                  <TextField
                    disabled={props.data}
                    name="Town"
                    label="Town"
                    margin="dense"
                    fullWidth
                    defaultValue={props.data && props.data.Town}
                    autoComplete="off"
                    inputRef={register({ required: 'Required' })}
                    error={errors.Town}
                    helperText={errors.Town && errors.Town.message}
                  />
                  <TextField
                    disabled={props.data}
                    name="PostCode"
                    label="Postcode"
                    margin="dense"
                    fullWidth
                    defaultValue={props.data && props.data.PostCode}
                    autoComplete="off"
                    inputRef={register({ required: 'Required' })}
                    error={errors.PostCode}
                    helperText={errors.PostCode && errors.PostCode.message}
                  />
                  <TextField
                    name="Price"
                    label="Price"
                    margin="dense"
                    autoComplete="off"
                    defaultValue={props.data && props.data.Price}
                    inputRef={register({ required: 'Required' })}
                    error={errors.Price}
                    helperText={errors.Price && errors.Price.message}
                  />
                  <Divider orientation="vertical" flexItem />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {props.data && (
                <IconButton
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setConfirm(true)
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <DeleteIcon />
                </IconButton>
              )}
              <Button
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
