import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import { useMutation } from '@apollo/react-hooks'
import { useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'

const restApi = 'http://frapp.cloud:3400'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
    maxWidth: '36ch',
  },
  listitem: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: '#ffffff',
  },
  inline: {
    display: 'inline',
  },
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  textfieldRoot: {
    marginTop: 0,
    marginRight: 5,
    width: 100,
  },
  image: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  paddingleft: {
    padding: 10,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
    },
  },
  iconbuttonroot: {
    padding: 0,
  },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export default function TrolleyItem(props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={4} md={3}>
          <img
            style={{ width: '100%', padding: 0, margin: 0 }}
            className={classes.image}
            src={`${restApi}/media/invoice/${props.data.ProductImage}`}
          />
        </Grid>
        <Grid item xs={8} md={9}>
          <div
            style={{
              fontWeight: 'bold',
              color: '#cf242a',
              marginTop: 0,
              marginBottom: 3,
              lineHeight: '1.2',
            }}
          >
            <span
              onClick={() => {
                props.setProduct(props.data)
                props.setOpenProduct(true)
              }}
            >
              {props.data.ProductName}
            </span>
          </div>
          <Grid container>
            <Grid item xs={12} md={12} style={{ textAlign: 'left' }}>
              <div
                style={{
                  fontWeight: 'bold',
                  margin: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                  color: 'black',
                  textAlign: 'left',
                  paddingRight: 3,
                }}
              >
                <span style={{ fontWeight: 'normal' }}>Unit Price : </span>
                RM {parseFloat(props.data.UnitPrice).toFixed(2)}
              </div>
            </Grid>
            <Grid item xs={6} md={6} style={{ textAlign: 'left' }}>
              Quantity :{' '}
              <span
                style={{
                  textAlign: 'center',
                  display: 'inline-block',
                  fontWeight: 'bold',
                }}
              >
                {props.data.Qty}
              </span>
              {/* <div
              style={{
                textAlign: 'left',
              }}
            >
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  margin: 0,
                  paddingTop: 2,
                  paddingBottom: 0,
                  color: '#cf242b',
                  textAlign: 'left',
                }}
              >
                {props.data.Qty} in trolley
              </div>
            </div> */}
            </Grid>

            <Grid item xs={12} md={12} style={{ textAlign: 'left' }}>
              <div
                style={{
                  fontWeight: 'bold',
                  margin: 0,
                  paddingTop: 0,
                  paddingBottom: 0,
                  color: 'black',
                  textAlign: 'left',
                  paddingRight: 3,
                }}
              >
                <span style={{ fontWeight: 'normal' }}>Total : </span>
                RM {parseFloat(props.data.InvoiceAmount).toFixed(2)}
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/* 
        <TextField
          name="Quantity"
          label=""
          classes={{ root: classes.textfieldRoot }}
          margin="dense"
          variant="outlined"
          value={props.data.Qty}
          type="number"
          autoComplete="off"
          onChange={(e) => {
            if (e.target.value)
              updateCart({
                variables: {
                  ProductID: props.data.ProductID,
                  PriceID: props.data.PriceID,
                  Qty: parseInt(e.target.value),
                },
              })
          }}
          inputProps={{ min: '0', step: 'any' }}
        /> */}
      </Grid>
      <Divider classes={{ root: classes.dividerroot }} />
    </React.Fragment>
  )
}
